.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
}

.modal-close {

  color: #999;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.user-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.user-list li {
  margin-bottom: 10px;
}
